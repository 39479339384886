@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);
  $background: map.get($config, background);
  $typography: mat.get-typography-config($theme);

  .full-screen {
    background: mat.get-color-from-palette($background, background-layout);

    .header {
      display: flex;
      align-items: center;
      gap: 4px;
      padding: 12px 8px;
      background-color: mat.get-color-from-palette($background, background-layout);
    }

    .full-screen-content {
      padding: 0 4px;
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow: auto;
    }

    .title {
      color: mat.get-color-from-palette($foreground, nm-top-bar);
      font-size: mat.font-size($typography, nm-top-bar);
      font-weight: mat.font-weight($typography, nm-top-bar);
      letter-spacing: mat.letter-spacing($typography, nm-top-bar);
      line-height: mat.line-height($typography, nm-top-bar);
    }
  }
}


.full-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  flex-direction: column;

  ::ng-deep {
    .menu {
      display: block;
    }

    .nm-menu-header {
      display: none;
    }

    .nm-menu-content{
      max-width: none !important;
      .items-container {
        max-height: none !important;
      }
    }
  }
}
