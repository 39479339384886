@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme($theme) {
  $config: mat.get-color-config($theme);
  $background: map.get($config, background);
  $foreground: map.get($config, foreground);
  $typography: mat.get-typography-config($theme);

  .hover {
    background: mat.get-color-from-palette($foreground, nm-table-header-hover);
  }

  nm-table-header {
    .color {
      background-color: mat.get-color-from-palette($foreground, nm-table-custom-color);
    }

    .border-left {
      border-left: 1px solid mat.get-color-from-palette($foreground, nm-table-header-border);
    }

    .border-right {
      border-right: 1px solid mat.get-color-from-palette($foreground, nm-table-header-border);
    }
    .group-header {
      grid-template-rows: 28px;
      height: 28px;
      display: grid;
      grid-auto-flow: column;
      width: 100%;
      user-select: none;

      .group {
        border: 1px solid mat.get-color-from-palette($foreground, nm-table-header-border);
        border-radius: 8px 8px 0 0;
        display: flex;
        align-items: center;
        justify-content: center;

        .column-title {
          font-size: mat.font-size($typography, text-2xs-500);
          font-weight: mat.font-weight($typography, text-2xs-500);
          letter-spacing: mat.letter-spacing($typography, text-2xs-500);
          line-height: mat.line-height($typography, text-2xs-500);
          color: mat.get-color-from-palette($foreground, nm-table-header-column-text);
        }
      }
    }
    .table-header {
      display: grid;
      grid-auto-flow: column;
      width: 100%;
      min-height: 57px;
      align-items: center;
      user-select: none;
      position: relative;
      z-index: 0;

      &.sticky-header {
        position: sticky;
        top: -1px;
        z-index: 20;
        background-color: mat.get-color-from-palette($foreground, nm-table-default-sticky-header-bg);
      }

      &.widget-table {
        background-color: mat.get-color-from-palette($background, nm-widget-header-background);
        border-bottom: 1px solid mat.get-color-from-palette($foreground, nm-table-divider);
      }
    }

    .column {
      display: flex;
      column-gap: 4px;
      overflow: visible;
      min-width: 64px;
      flex-grow: 0;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      color: mat.get-color-from-palette($foreground, nm-table-header-column-text);
      font-size: mat.font-size($typography, nm-table-cell-header);
      font-weight: mat.font-weight($typography, nm-table-cell-header);
      line-height: mat.line-height($typography, nm-table-cell-header);
      position: relative;

      &.pinned {
        &.widget-table {
          background: mat.get-color-from-palette($foreground, nm-table-header-widget);
        }
      }

      &.widget-table {
        border-bottom: none;
      }

      &.pinned:hover::after {
        background: mat.get-color-from-palette($background, nm-table-header-pinner-hover);
        height: 10px;
      }

      .bar {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 5px;
        justify-self: flex-end;
        background: mat.get-color-from-palette($foreground, nm-table-header-bar);
        background-clip: content-box;
        opacity: 0;
        z-index: 999;
        cursor: col-resize;

        .bar-back {
          height: 100%;
          width: 2000px;
          margin-left: -1000px;
          display: none;
        }

        &:hover,
        &:active {
          opacity: 1;
        }

        &:active {
          height: 10000px;

          .bar-back {
            display: block;
          }
        }
      }

      .column-title {
        overflow: hidden;
      }
      .column-tooltip {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 14px;
        height: 14px;
        background-color: mat.get-color-from-palette($foreground, nm-divider);
        border-radius: 50%;
        color: mat.get-color-from-palette($foreground, nm-autocomplete);
        font-size: 12px;
      }

      &.select-column {
        justify-content: center;

        .checkbox-wrapper {
          width: 40px;
          height: 40px;
        }
      }
      &.number-column {
        justify-content: center;
        color: mat.get-color-from-palette($foreground, nm-table-enumerator);
        min-width: unset;
        padding-left: 0;
        padding-right: 0;

        &.widget-table {
          background: mat.get-color-from-palette($foreground, nm-table-header-widget);
        }
      }
      .number {
        color: mat.get-color-from-palette($foreground, nm-table-enumerator);
      }

      &.sortable {
        cursor: pointer;
      }

      &.auto-width {
        max-width: 214px;
      }

      nm-svg-icon {
        display: flex;
        align-items: center;
      }
    }

    .drag-placeholder {
      width: 100%;
      height: 100%;
      background: mat.get-color-from-palette($foreground, nm-table-drop-placeholder);
    }
  }
}
