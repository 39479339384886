@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);
  $background: map.get($config, background);
  $typography: mat.get-typography-config($theme);

  .dropdown-menu-panel {
    width: 100%;
    min-width: 140px;
    max-width: 100%;
    overflow-y: auto;

    .loading {
      height: 48px;
    }
  }

  .overflow-panel {
    .nm-autocomplete-list {
      .nm-menu-content {
        .items-container {
          max-height: 300px !important;
          overflow-y: auto;
        }
      }
    }
  }

  .create-menu-item,
  .no-results-item {
    .nm-menu-item.mat-menu-item {
      padding: 0 12px;
    }
  }
  .no-results-item {
    .nm-menu-item.mat-menu-item {
      background-color: inherit !important;
      cursor: default !important;
      .label {
        cursor: default;
      }
    }
  }

  .select-all-wrapper {
    padding-top: 8px;

    .divider {
      margin-top: 0;
    }
  }

  .nm-autocomplete-disabled nm-dropdown-menu {
    pointer-events: none;
  }

  .nm-autocomplete {
    display: flex;
    min-height: 40px;
    flex-direction: column;
    row-gap: 4px;

    .placeholder {
      display: flex;
      align-items: center;

      height: 32px;
      color: mat.get-color-from-palette($foreground, nm-autocomplete-placeholder);
      font-size: mat.font-size($typography, text-sm-400);
      font-weight: mat.font-weight($typography, text-sm-400);
      letter-spacing: mat.letter-spacing($typography, text-sm-400);
      line-height: mat.line-height($typography, text-sm-400);

      &.selected-value {
        color: mat.get-color-from-palette($foreground, nm-form-field-text);
      }
    }

    input::placeholder {
      color: mat.get-color-from-palette($foreground, nm-autocomplete-placeholder);
      font-size: mat.font-size($typography, text-sm-400);
      font-weight: mat.font-weight($typography, text-sm-400);
      letter-spacing: mat.letter-spacing($typography, text-sm-400);
      line-height: mat.line-height($typography, text-sm-400);
    }

    .value-color {
      width: 24px;
      height: 24px;
      border-radius: 4px;
    }

    .value-prefix {
      margin-right: 8px;
    }

    .dense-mode {
      .left-block .text-wrapper {
        padding: 0 !important;
        margin: 0;
      }

      .selected-value {
        display: flex;
        height: 32px;
        align-items: center;
      }

      input {
        height: 32px;
      }

      .value-wrapper {
        padding-left: 4px;
        padding-top: 4px;
        padding-bottom: 4px;
        row-gap: 4px;
        margin-left: -12px;
      }
    }

    .value-wrapper {
      display: flex;
      flex-grow: 0;
      flex-wrap: nowrap;
      align-items: center;

      .remain-chips {
        margin-left: 4px;
        color: mat.get-color-from-palette($foreground, nm-autocomplete-placeholder);
        font-size: mat.font-size($typography, text-sm-400);
        font-weight: mat.font-weight($typography, nm-chip);
        letter-spacing: mat.letter-spacing($typography, text-sm-400);
        line-height: mat.line-height($typography, text-sm-400);
      }

      nm-chip {
        margin-right: 4px;

        &:last-of-type {
          margin-right: 0;
        }
      }

      &.single-color-value {
        padding-left: 8px;
      }

      &:has(.nm-chip) input {
        padding-left: 8px;
      }
    }

    .icons-wrapper {
      display: flex;
      align-items: center;
      column-gap: 8px;
    }

    .arrow-icon.opened {
      transform: rotate(180deg);
    }

    .nm-form-field-disabled {
      .placeholder,
      .remain-chips {
        color: mat.get-color-from-palette($foreground, nm-autocomplete-disabled);
      }

      .value-prefix {
        opacity: 0.38;
      }
    }

    &.autocomplete-disabled {
      user-select: none;
      pointer-events: none;
    }

    .menu-opened {
      .clear-icon {
        display: none;
      }
    }

    .nm-form-field {
      min-height: 40px;
      align-items: flex-start;

      input {
        //width: auto;
        flex-grow: 1;
      }

      .content-wrapper {
        align-items: stretch;

        .right-block {
          align-items: start;

          .arrow-icon,
          .clear-icon {
            cursor: pointer;
          }
        }
      }
    }

    .nm-chip {
      max-width: 220px;
      height: 32px;
    }
    .all-selected-chip > .nm-chip {
      max-width: 220px;
    }
  }

  .nm-autocomplete-list {
    .nm-menu-item {
      padding: 4px 8px;

      .content-container .left {
        gap: 8px;
      }
    }
  }
}
