@use '@angular/material' as mat;
@use 'sass:map';

:host {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 1000;

  //::ng-deep {
  //  .nm-menu-item.mat-menu-item {
  //    margin: 0 -12px;
  //  }
  //}

  ::ng-deep {
    .mat-ripple{
      min-width: 50% !important;
    }
  }
}

@mixin theme($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);
  $background: map.get($config, background);
  $typography: mat.get-typography-config($theme);

  .bottom-sheet-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    animation: fadeIn 0.3s;
  }

  .bottom-sheet-content {
    background: mat.get-color-from-palette($background, background-layout);
    width: 100%;
    border-top-left-radius: 28px;
    border-top-right-radius: 28px;
    padding: 0  16px 16px;
    max-height: 80%;
    overflow-y: auto;
    touch-action: none;
    animation: slideUp 0.3s;
    z-index: 1;
    display: flex;
    flex-direction: column;

    .drag-handler-div {
      padding: 16px;
      display: flex;
      justify-content: center;

      .drag-handler {
        width: 32px;
        height: 4px;
        border-radius: 100px;
        background: mat.get-color-from-palette($background, drag-handler);
      }
    }

    .title {
      font-size: mat.font-size($typography, text-xl-600);
      font-weight: mat.font-weight($typography, text-xl-600);
      letter-spacing: mat.letter-spacing($typography, text-xl-600);
      line-height: mat.line-height($typography, text-xl-600);
    }
  }

  /* Анимация появления модального окна */
  @keyframes slideUp {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0);
    }
  }

  /* Анимация затемнения фона */
  @keyframes fadeIn {
    0% {
      background-color: rgba(0, 0, 0, 0);
    }
    100% {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
}
