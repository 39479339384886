@use '@angular/material' as mat;
@use 'sass:map';

nm-filter-period-new .filter-label {
  max-width: 236px !important;
  justify-content: start !important;
  .filter-label-left-side {
    max-width: 100% !important;
  }
}

@mixin theme($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);
  $typography: mat.get-typography-config($theme);
  .filter-period-new-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.mobile {
      justify-content: flex-start;
      padding: 0 4px;

      .back {
        padding: 8px 0 8px 8px;
      }
    }

    .back {
      display: flex;
      align-items: center;
      height: 48px;
      color: mat.get-color-from-palette($foreground, nm-filter-header-color);
      font-size: mat.font-size($typography, nm-filter-header);
      font-weight: mat.font-weight($typography, nm-filter-header);
      letter-spacing: mat.letter-spacing($typography, nm-filter-header);
      line-height: mat.line-height($typography, nm-filter-header);
      padding: 8px 0 8px 12px;
      margin-top: 0 !important;

      nm-icon-button {
        margin-right: 8px;
      }
    }
  }

  .filter-period-new {
    position: relative;
    cursor: pointer;

    ::ng-deep {
      .nm-form-field {
        .text-wrapper {
          position: static !important;
          user-select: none;
        }
      }
    }

    .field {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      visibility: hidden;
    }
  }

  .calendar-grid {
    width: 560px;
    display: grid;
    grid-template-columns: auto auto;

    &.mobile {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;
    }
  }

  .calendar-menu {
    width: 280px;
    &.mobile {
      width: 100%;
    }
    .preset-selector {
      display: block;
    }

    nm-menu-content {
      padding: 0;
    }
  }

  .calendar-block {
    width: 280px;
    display: flex;
    flex-direction: column;

    &.mobile {
      width: 100%;
    }

    .calendar-input {
      padding: 0 12px;
      &.compare {
        margin-top: 8px;
        width: 280px;

        .nm-input-control {
          width: 100%;
        }
      }
    }

    .calendar-item {
      width: 280px;
      .mat-calendar {
        width: 100%;
        padding: 0 12px 16px 12px;

        .mat-calendar-header {
          padding: 0;
          .mat-calendar-controls {
            margin: 0 0 8px 0;
          }
        }

        .mat-calendar-content {
          margin: 0 16px;
          padding: 0;
        }
      }
    }

    .controls-button {
      display: flex;
      padding: 12px 16px 24px 18px;
      gap: 8px;
      justify-content: flex-end;

      nm-button {
        min-width: 113px;
      }
    }
  }
}

.checkbox {
  display: flex;
  align-items: center;
  gap: 7px;
  font-size: 14px;
  padding-right: 12px;
}
